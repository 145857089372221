import React from 'react';
import './HeadingBar.css';

function HeadingBar () {
    return (
        <div id = "HeadTitle">
            <h2 id = "title"> StreetZoom.in</h2>
        </div>
    );
};

export default HeadingBar;