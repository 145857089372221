import React from 'react';
import './FooterBar.css';

function FooterBar () {
    return (
        <div id = "Footer-Details">
            <p id = "Footer">Contact us: Lawsons bay Colony, Visakhapatnam </p>
            <h2 id = "Footer-logo">StreetZoom.in</h2>
        </div>
    );
};

export default FooterBar;