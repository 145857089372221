import React from 'react';
import './Dealblock.css';
import dealimage from './Deal0706/bedsheet.jpg';

function Deal8 () {
    return (
        <div id = "Dealbox">
            <div>
            <a href="https://amzn.to/3Vih3yS" target="_blank" rel="noopener noreferrer">
                <img id = "dealimage" src = {dealimage} alt = "not found" />
                </a>
            </div>
            <div>
            <a id = "textlink" href="https://amzn.to/3Vih3yS" target="_blank" rel="noopener noreferrer">
                <div id = "desc">
                    <h3 id = "description">Description : </h3>
                    <p id = "description"> Radhika Store 100% Cotton 160TC Ludo Printed Double Bedsheet with 2 Pillow Covers, 1 Dice and 16 Tokens :King Size_Multicolour,Paisley </p>
                </div>
                <div id = "disc">
                    <h3 id = "discount">Discount :</h3>
                    <p id = "discount"> 63% </p>
                </div>
                <div id = "mrps">
                    <h3 id = "mrp">M.R.P. : </h3>
                    <p id = "mrpcross"> Rs. 1,499 </p>
                </div>
                <div>
                    <h3 id ="offerprice">Offer Price : </h3>
                    <p id ="offerprice"> Rs. 548 </p>
                </div>
                </a>
            </div>
    </div>
    );
};

export default Deal8;