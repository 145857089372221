import React from 'react';
import './Dealblock.css';
import dealimage from './Deal0706/carschoolbag.jpg';

function Deal7 () {
    return (
        <div id = "Dealbox">
            <div>
            <a href="https://amzn.to/3VIhAMh" target="_blank" rel="noopener noreferrer">
                <img id = "dealimage" src = {dealimage} alt = "not found" />
                </a>
            </div>
            <div>
            <a id = "textlink" href="https://amzn.to/3VIhAMh" target="_blank" rel="noopener noreferrer">
                <div id = "desc">
                    <h3 id = "description">Description : </h3>
                    <p id = "description"> KARDIFF Car Shape Kids Backpack, Bag for Boys and Girls, Primary, Nursery School Bag for Kids </p>
                </div>
                <div id = "disc">
                    <h3 id = "discount">Discount :</h3>
                    <p id = "discount"> 50% </p>
                </div>
                <div id = "mrps">
                    <h3 id = "mrp">M.R.P. : </h3>
                    <p id = "mrpcross"> Rs. 499 </p>
                </div>
                <div>
                    <h3 id ="offerprice">Offer Price : </h3>
                    <p id ="offerprice"> Rs. 249 </p>
                </div>
                </a>
            </div>
    </div>
    );
};

export default Deal7;