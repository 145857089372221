import React from 'react';
import './Dealblock.css';
import dealimage from './Deal0706/flemingobag.jpg';

function Deal1 () {
    return (
        <div id = "Dealbox">
            <div>
            <a href="https://amzn.to/3XbzaJu" target="_blank" rel="noopener noreferrer">
                <img id = "dealimage" src = {dealimage} alt = "not found" />
                </a>
            </div>
            <div>
            <a id = "textlink" href="https://amzn.to/3XbzaJu" target="_blank" rel="noopener noreferrer">
                <div id = "desc">
                    <h3 id = "description">Description : </h3>
                    <p id = "description"> ShopyVid School Bag for Girls with Lunch Bag/School Bag for Girls 10 Years/School Bag for Girls Kids for 5-7 Year </p>
                </div>
                <div id = "disc">
                    <h3 id = "discount">Discount :</h3>
                    <p id = "discount"> 60% </p>
                </div>
                <div id = "mrps">
                    <h3 id = "mrp">M.R.P. : </h3>
                    <p id = "mrpcross"> Rs. 1,799 </p>
                </div>
                <div>
                    <h3 id ="offerprice">Offer Price : </h3>
                    <p id ="offerprice"> Rs. 719 </p>
                </div>
                </a>
            </div>
        </div>
 
    );
};

export default Deal1;