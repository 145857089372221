import './App.css';

//import BarHead from './components/BarHead';
//import ExitPoll from './components/ExitPoll';
import HeadingBar from './components/HeadingBar';
import FooterBar from './components/FooterBar';
import Streetzoom from './components/Streetzoom';

function App () {

  return (
      <div className="App">
        <header className="App-header">
          <HeadingBar></HeadingBar>
 {/*         <BarHead></BarHead>*/}
       </header>
       <Streetzoom></Streetzoom>
        <footer className = "App-footer">
            <FooterBar></FooterBar>
        </footer>
    </div>
  );
}

export default App;
