// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#HeadTitle {
    height: 50px;
    background-color: rgb(243, 141, 243);
    text-align: left;
    padding-left: 10px;

}


#title {
    display: inline;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(0, 32, 96);
    margin: 0px;
    padding-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeadingBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oCAAoC;IACpC,gBAAgB;IAChB,kBAAkB;;AAEtB;;;AAGA;IACI,eAAe;IACf,4DAA4D;IAC5D,qBAAqB;IACrB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["#HeadTitle {\r\n    height: 50px;\r\n    background-color: rgb(243, 141, 243);\r\n    text-align: left;\r\n    padding-left: 10px;\r\n\r\n}\r\n\r\n\r\n#title {\r\n    display: inline;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    color: rgb(0, 32, 96);\r\n    margin: 0px;\r\n    padding-top: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
