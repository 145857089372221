import React from 'react';
import './DealOffer.css';
import Deal1 from './Deal1';
import Deal2 from './Deal2';
import Deal3 from './Deal3';
import Deal4 from './Deal4';
import Deal5 from './Deal5';
import Deal6 from './Deal6';
import Deal7 from './Deal7';
import Deal8 from './Deal8';
import Deal9 from './Deal9';

function DealOffer () {
    return (
        <div>
            <div id = "Dealoff">
                <Deal1></Deal1>
                <Deal2></Deal2>
                <Deal3></Deal3>
            </div>
            <div id = "Dealoff">
                <Deal4></Deal4>
                <Deal5></Deal5>
                <Deal6></Deal6>
            </div>
            <div id = "Dealoff">
                <Deal7></Deal7>
                <Deal8></Deal8>
                <Deal9></Deal9>
            </div>
        </div>
        );
};

export default DealOffer;