import React from 'react';
import './Streetzoom.css';
import Exitpoll from './Exitpoll';
import DealOffer from './DealOffer';

function Streetzoom () {
    return (
        <div>
            <Exitpoll></Exitpoll>
            <DealOffer></DealOffer>
        </div>
    );
};

export default Streetzoom;