// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Footer-Details {
    height: 50px;
    background-color: rgb(161, 8, 161);
    text-align: left;
    padding-left: 10px;

}

#Footer {
    display: inline;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(235, 233, 232);
    margin: 0px;
    padding-top: 5px;
}

#Footer-logo {
    display: inline;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(247, 244, 243);
    margin: 0px;
    margin-left: 50%;
    padding-top: 5px;
}

@media (max-width: 768px) {
    /* Adjustments for small screens */
    #Footer-logo {
        margin-left: 25%;
     }
  }`, "",{"version":3,"sources":["webpack://./src/components/FooterBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kCAAkC;IAClC,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;IACI,eAAe;IACf,4DAA4D;IAC5D,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,4DAA4D;IAC5D,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC;QACI,gBAAgB;KACnB;EACH","sourcesContent":["#Footer-Details {\r\n    height: 50px;\r\n    background-color: rgb(161, 8, 161);\r\n    text-align: left;\r\n    padding-left: 10px;\r\n\r\n}\r\n\r\n#Footer {\r\n    display: inline;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    color: rgb(235, 233, 232);\r\n    margin: 0px;\r\n    padding-top: 5px;\r\n}\r\n\r\n#Footer-logo {\r\n    display: inline;\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    color: rgb(247, 244, 243);\r\n    margin: 0px;\r\n    margin-left: 50%;\r\n    padding-top: 5px;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    /* Adjustments for small screens */\r\n    #Footer-logo {\r\n        margin-left: 25%;\r\n     }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
