import React from 'react';
import './Exitpoll.css';
import anouncement from './images/anouncement.png';
import APWar from './images/APWar.jpg';

function Exitpoll () {
    return (
        <div id ="DealDay">
                    <div id = "BlockA">
                        <div id ="DealBlock">
                            <div>
                                {/*<h2 id= "DealHead">Election Deal</h2>*/}
                            </div>
                            <div id = "BlockD">
                                <div>
                                <img id = "anouncement" src={anouncement} alt="not found "></img>
                            </div>
                            <div id = "BlockC">
                                <img id = "Poll-War" src={APWar} alt="not found "></img>
                            </div>
                            </div>
                        </div>
                    </div>
            <div id = "BlockB">
                <div id = "prediction">
                    <p>Andhra Pradesh General Elections - 2024</p>
                        <table border = "1" id = "Deal-Table">
                            <thead>
                            <tr>
                            <th > Political <br></br> Party </th>
                            <th > Assembly <br></br> Contested </th>
                            <th > Assembly <br></br> Won </th>
                            <th > Parliament <br></br> Contested </th>
                            <th > Parliament <br></br> Won </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                            <td> TDP </td>
                            <td> 144 </td>
                            <td> 135 </td>
                            <td> 17 </td>
                            <td> 16 </td>
                            </tr>
                            <tr >
                            <td> Janasena </td>
                            <td> 21 </td>
                            <td> 21 </td>
                            <td> 02 </td>
                            <td> 02 </td>
                            </tr>
                            <tr >
                            <td> YCP </td>
                            <td> 175 </td>
                            <td> 11 </td>
                            <td> 25 </td>
                            <td> 04 </td>
                            </tr>
                            <tr >
                            <td>BJP</td>
                            <td> 10 </td>
                            <td> 08 </td>
                            <td> 06 </td>
                            <td> 03 </td>
                            </tr>
                        </tbody>
                        </table>
                </div>
            </div>
        </div>
    );
};

export default Exitpoll;