// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Dealouter {
    display: inline-block;
}
#Dealbox {
    display: flex;
    flex-direction: row;
    width: 32vw;
    margin: 5px;
    background-color: aquamarine;
}

#dealimage {
    width: 15vw;
    display: inline;
    margin: 5px;
}

#textlink {
    text-decoration: none;
}

#desc {
    padding :5px;
}

#disc {
    padding : 5px;
}

#description {
    display: inline;
}

#mrps {
    padding : 5px;
}
#discount {
    display: inline;
}

#mrp {
    display: inline;
}

#mrpcross {
    display: inline;
    text-decoration: line-through;
}

#offerprice {
    display: inline;
}

@media (min-width : 600px) and (max-width : 1050px) {
    #Dealbox {
        flex-direction: column;
    }
    #dealimage {
        width: 30vw;
    }

}

@media only screen and (max-width: 600px) {
    #Dealbox {
        width: 90vw;
    }
    #dealimage {
        width: 45vw;
    }
      }`, "",{"version":3,"sources":["webpack://./src/components/Dealblock.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,eAAe;IACf,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;;AAEJ;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;MACE","sourcesContent":["#Dealouter {\r\n    display: inline-block;\r\n}\r\n#Dealbox {\r\n    display: flex;\r\n    flex-direction: row;\r\n    width: 32vw;\r\n    margin: 5px;\r\n    background-color: aquamarine;\r\n}\r\n\r\n#dealimage {\r\n    width: 15vw;\r\n    display: inline;\r\n    margin: 5px;\r\n}\r\n\r\n#textlink {\r\n    text-decoration: none;\r\n}\r\n\r\n#desc {\r\n    padding :5px;\r\n}\r\n\r\n#disc {\r\n    padding : 5px;\r\n}\r\n\r\n#description {\r\n    display: inline;\r\n}\r\n\r\n#mrps {\r\n    padding : 5px;\r\n}\r\n#discount {\r\n    display: inline;\r\n}\r\n\r\n#mrp {\r\n    display: inline;\r\n}\r\n\r\n#mrpcross {\r\n    display: inline;\r\n    text-decoration: line-through;\r\n}\r\n\r\n#offerprice {\r\n    display: inline;\r\n}\r\n\r\n@media (min-width : 600px) and (max-width : 1050px) {\r\n    #Dealbox {\r\n        flex-direction: column;\r\n    }\r\n    #dealimage {\r\n        width: 30vw;\r\n    }\r\n\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    #Dealbox {\r\n        width: 90vw;\r\n    }\r\n    #dealimage {\r\n        width: 45vw;\r\n    }\r\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
