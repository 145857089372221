import React from 'react';
import './Dealblock.css';
import dealimage from './Deal0706/lunchbox.jpg';

function Deal4 () {
    return (
        <div id = "Dealbox">
            <div>
            <a href="https://amzn.to/45c8Elj" target="_blank" rel="noopener noreferrer">
                <img id = "dealimage" src = {dealimage} alt = "not found" />
                </a>
            </div>
            <div>
            <a id = "textlink" href="https://amzn.to/45c8Elj" target="_blank" rel="noopener noreferrer">
                <div id = "desc">
                    <h3 id = "description">Description : </h3>
                    <p id = "description"> Milton Pro Lunch Tiffin 3 Microwave Safe Inner Steel Containers; 1 Plastic Chutney Dabba; 1 Aqua Steel Bottle, Steel Spoon and Fork With Insulated Fabric Jacket </p>
                </div>
                <div id = "disc">
                    <h3 id = "discount">Discount :</h3>
                    <p id = "discount"> 58% </p>
                </div>
                <div id = "mrps">
                    <h3 id = "mrp">M.R.P. : </h3>
                    <p id = "mrpcross"> Rs. 1,999 </p>
                </div>
                <div>
                    <h3 id ="offerprice">Offer Price : </h3>
                    <p id ="offerprice"> Rs. 848 </p>
                </div>
                </a>
            </div>
    </div>
    );
};

export default Deal4;