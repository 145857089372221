import React from 'react';
import './Dealblock.css';
import dealimage from './Deal0706/umbrella.jpg';

function Deal9 () {
    return (
        <div id = "Dealbox">
            <div>
            <a href="https://amzn.to/3KBLhYI" target="_blank" rel="noopener noreferrer">
                <img id = "dealimage" src = {dealimage} alt = "not found" />
                </a>
            </div>
            <div>
            <a id = "textlink" href="https://amzn.to/3KBLhYI" target="_blank" rel="noopener noreferrer">
                <div id = "desc">
                    <h3 id = "description">Description : </h3>
                    <p id = "description"> BAAL Rain Protection Umbrella For School Kids, Set of 6 </p>
                </div>
                <div id = "disc">
                    <h3 id = "discount">Discount :</h3>
                    <p id = "discount"> 17%% </p>
                </div>
                <div id = "mrps">
                    <h3 id = "mrp">M.R.P. : </h3>
                    <p id = "mrpcross"> Rs. 1,199 </p>
                </div>
                <div>
                    <h3 id ="offerprice">Offer Price : </h3>
                    <p id ="offerprice"> Rs. 999 </p>
                </div>
                </a>
            </div>
    </div>
    );
};

export default Deal9;