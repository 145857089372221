// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Dealoff {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width : 600px ) {
    #Dealoff {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/DealOffer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":["#Dealoff {\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n@media only screen and (max-width : 600px ) {\r\n    #Dealoff {\r\n        flex-direction: column;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
